.createblog {
    margin: 2rem 5rem;
    border-radius: 1 rem;
    overflow: hidden;
    background-color: azure;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.title {
    background-color: aquamarine;
    padding: 1rem;
}

.input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 2rem;
}

.inputtitle {
    margin: 1rem;
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.inputtitle > label {
    font-size: 1.5rem;
}

.inputtitle > input {
    width: 40rem;
    height: 2.5rem;
    padding: 5px;
    font-size: 20px;
}

.inputdec {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.inputdec > label {
    font-size: 1.5rem;
}

.inputdec > textarea {
    width: 40rem;
    height: 10rem;
    padding: 10px;
    font-size: 14px;
}

textarea:focus,
input:focus {
    outline: none;
}

.createbtn {
    padding: 0.5rem;
    font-size: 1.5rem;
    border: none;
    background-color: aquamarine;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: bold;
    color: rgb(5, 5, 128);
}

.createbtn:hover {
    background-color: rgb(64, 249, 187);
}

@media (max-width: 998px) {
    .createblog {
        margin: 1rem 0.5rem;
    }

    .inputdec,
    .inputtitle {
        flex-direction: column;
    }

    .inputtitle > input,
    .inputdec > textarea {
        width: 30rem;
    }

    .inputtitle {
        margin: 1rem;
        margin-right: 100px;
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}
