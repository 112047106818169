* {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    display: none;
}
