.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    background-color: aquamarine;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.logo {
    font-size: 2.5rem;
    margin-left: 2rem;
    font-weight: bold;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-variant: small-caps;
    text-shadow: rgba(58, 98, 86, 0.24) 0px 10px 10px;
}

.pages {
    display: flex;
    margin-right: 0;
}

.page {
    font-size: 20px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bolder;
    cursor: pointer;
    color: rgb(5, 4, 128);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-variant: small-caps;
    text-shadow: rgba(58, 98, 86, 0.24) 0px 10px 10px;
}

.page:hover {
    text-decoration: underline;
    color: green;
}
