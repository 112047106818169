.loginpage {
    width: 100%;
    height: 89vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    background-color: aliceblue;
}

.loginpage div span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginpage span {
    font-size: 1.5rem;
    color: #443343;
    font-weight: bold;
}

.googlebtn {
    border: none;
    border-radius: 5px;
    padding: 10px 16px 12px 60px;
    cursor: pointer;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    background-image: url("../../assets/google.png");
    background-repeat: no-repeat;
    background-position: 15px 11px;
    background-color: white;
    height: 60px;
    background-size: contain;
    resize: both;
    background-size: 35px 35px;
    font-size: 25px;
    font-weight: 500;
    color: #757575;
}

.googlebtn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
}
