.blog {
    background-color: azure;
    margin: 0.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.blogheader {
    background-color: aquamarine;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 0rem 0.8rem;
    justify-content: center;
    align-content: center;
}

.userdata {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileimage {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 5px;
}

.authorname {
    font-size: 14px;
    color: rgb(81, 81, 81);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: bolder;
}

.timestamp {
    font-size: 10px;
    color: gray;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: rgb(5, 5, 128);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    text-shadow: rgba(58, 98, 86, 0.24) 0px 10px 10px;
    text-transform: capitalize;
    font-size: x-large;
}

.deleteblog {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
}

.deleteblog button {
    font-size: 2rem;
    border: none;
    background-color: aquamarine;
    cursor: pointer;
}

.desc {
    padding: 1rem;
    font-size: 12px;
}

@media (max-width: 998px) {
    .blogheader {
        grid-template-columns: 8rem 2fr 3rem;
        padding-left: 0.5rem;
    }

    .authorname {
        font-size: 12px;
    }
}
