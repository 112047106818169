.homepage {
    margin: 2rem 5rem;
}

.header {
    background-color: aquamarine;
    height: 3.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.header span {
    font-size: 2rem;
    color: rgb(5, 5, 128);
    font-weight: 600;
    text-shadow: rgba(58, 98, 86, 0.24) 0px 10px 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.blogCard {
    margin-top: 1rem;
}

@media (max-width: 998px) {
    .homepage {
        margin: 1rem 0.5rem;
    }
}
